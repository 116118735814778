import Table from '@mui/material/Table';
import React from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import Box from "@material-ui/core/Box";
import code2station from './../../resources/code2station.json';

class ApproachingPage extends React.Component {

    constructor(props, open) {
        super(props);
        this.open = open;
      }

    CreateCaption(syncedTime) {
        if (syncedTime.getTime() === 0) {
            return <caption>Last Synced: Never</caption>
        } else if ((syncedTime.getTime() + 60000) > Date.now()) {
            return <caption>Last Synced: {syncedTime.toLocaleString('en-GB')}</caption>
        } else {
            return <caption style={{ color: 'red' }}>Last Synced: {syncedTime.toLocaleString('en-GB')}</caption>
        }
    }

    ApproachingTableData(approachingData, open, setOpen) {
        var StationRows = [];
        var i = 0;
        for (var station in approachingData.all_time) {
            StationRows.push(this.ApproachingTableStationRow(approachingData.all_time[station], i, station, open, setOpen, false));
            i = i + 1;
        }
        for (station in approachingData.peak_time) {
            StationRows.push(this.ApproachingTableStationRow(approachingData.peak_time[station], i, station, open, setOpen, true));
            i = i + 1;
        }
        return StationRows;
    }

    ApproachingTableStationRow(trains_list, i, station, open, setOpen, ignoreEmpty) {
        var TrainRows;
        if (trains_list !== null) {
            TrainRows = [<TableRow hover role="checkbox" onClick={(e) => {var newopen = open; newopen[i] = !open[i]; setOpen(newopen);}}>
                {this.CreateOriginCell(code2station[station])}
                {this.CreatePlatformCell(trains_list[0])}
                {this.CreateDepartureTimeCell(trains_list[0])}
            </TableRow>];
            TrainRows.push(this.ApproachingTableTrainRows(trains_list, i, open, setOpen));
            return TrainRows;
        } else {
            if (!ignoreEmpty) {
                TrainRows = [<TableRow hover role="checkbox" onClick={(e) => {var newopen = open; newopen[i] = !open[i]; setOpen(newopen);}}>
                    {this.CreateOriginCell(code2station[station])}
                    {this.CreatePlatformCell(0)}
                    {this.CreateDepartureTimeCell(0)}
                </TableRow>];
                return TrainRows;
            }
        }
    }

    ApproachingTableTrainRows(trains_list, i, open, setOpen) {
        const columns = [
            { id: 'destination', label: 'Destination', Width: '100%', align: 'left', },
            { id: 'platform', label: 'Platform', Width: '10%', align: 'center', },
            { id: 'departure', label: 'Departure', Width: '20%', align: 'right', },
        ];
        var trainRows = [];
            for (var j = 0; j < trains_list.length; j++) {
                trainRows.push(this.ApproachingTableTrainRow(trains_list[j]));
            }
        return ([
            <React.Fragment>
                <TableRow hover role="checkbox">
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, width: '100%' }} colSpan={4} >
                        <Collapse in={open[i]} timeout="auto" unmountOnExit style={{ width: '100%' }} >
                            <Box margin={1} style={{ width: '100%' }} onClick={(e) => {var newopen = open; newopen[i] = !open[i]; setOpen(newopen);}}>
                                <h4>Trains in the next hour</h4>
                                <Table size="small" aria-label="purchases" style={{ width: '100%' }} >
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ width: column.width }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {trainRows}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        ]);
    }

    ApproachingTableTrainRow(train_json) {
        return ([
            <TableRow>
                {this.CreateDestinationCell(train_json)}
                {this.CreatePlatformCell(train_json)}
                {this.CreateDepartureTimeCell(train_json)}
            </TableRow>
        ]);
    }

    CreateOriginCell(train_origin) {
        return <TableCell align="left">{train_origin}</TableCell>;
    }

    CreateDestinationCell(train_json) {
        if (train_json.slow) {
            return <TableCell align="left">{train_json.destination}</TableCell>;
        } else {
            return <TableCell align="left">{train_json.destination} <i>Fast</i></TableCell>;
        }
    }

    CreateArrivalTimeCell(train_json) {
        if (train_json.estimated_arrival === "On time") {
            return <TableCell align="right">{train_json.scheduled_arrival}</TableCell>
        } else {
            return <TableCell align="right"><s>{train_json.scheduled_arrival}</s> {train_json.estimated_arrival}</TableCell>
        }
    }

    CreateDepartureTimeCell(train_json) {
        if (train_json.estimated_departure === "On time") {
            return <TableCell align="right">{train_json.scheduled_departure}</TableCell>
        } else {
            return <TableCell align="right"><s>{train_json.scheduled_departure}</s> {train_json.estimated_departure}</TableCell>
        }
    }

    CreatePlatformCell(train_json) {
        return <TableCell align="center">{train_json.platform}</TableCell>
    }

    render(approachingData, syncedTime, open, setOpen, width, height, searchParams) {
        const columns = [
            { id: 'train', label: 'Station', width: '100%', align: 'left', },
            { id: 'platform', label: 'Platform', width: '5%', align: 'center', },
            { id: 'time', label: 'Departure', width: '50%', align: 'right', },
        ];
        return (
            <Paper sx={{ width: width - 48, height: height - (48 + 48 + (height * 0.01)), overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: height - (48 + 48 + (height * 0.01)) }}>
                    <h3>Next central London trains to {searchParams.get("station")}</h3>
                    <Table stickyHeader aria-label="sticky table">
                        { this.CreateCaption(syncedTime) }
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ width: column.width }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.ApproachingTableData(approachingData, open, setOpen)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }
}

export default ApproachingPage;