import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FrogSwitch from './frogSwitch';
import DarkSwitch from './darkSwitch';
import FrogFile from './../../resources/frog.svg';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function SettingsPage(width, height, searchParams, setSearchParams, StationData, setSyncedTime, setDepartingData, setApproachingData) {

    return (
        <Paper sx={{ width: width - 48, height: height - (48 + 48 + (height * 0.01)), overflow: 'hidden' }}>
            <TableContainer sx={{ width: width - 48, maxHeight: height - (48 + 48 + (height * 0.01)) }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <FormControl sx={{ width: width - 48 - 48 }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Station</InputLabel>
                                    <Select
                                        defaultValue="Ten"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={searchParams.get("station")}
                                        label="Station"
                                        onChange={(event) => {
                                            searchParams.set("station", event.target.value);
                                            setSearchParams(searchParams);
                                            setDepartingData([])
                                            setApproachingData({})
                                            setSyncedTime(new Date(0));
                                        }}>
                                        {StationData.map((column) => (
                                            <MenuItem value={column}>{column}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <h2>Frog mode</h2>
                            </TableCell>
                            <TableCell align={"right"}>
                                <FrogSwitch
                                    checked={searchParams.get("frogmode") === "true"}
                                    onChange={(event) => {
                                        searchParams.set("frogmode", event.target.checked.toString());
                                        setSearchParams(searchParams);
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <h2>Dark mode</h2>
                            </TableCell>
                            <TableCell align={"right"}>
                                <DarkSwitch
                                    checked={searchParams.get("darkmode") === "true"}
                                    onChange={(event) => {
                                        searchParams.set("darkmode", event.target.checked.toString());
                                        setSearchParams(searchParams);
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <h2>Premium (99p)</h2>
                                <ul>
                                    <li><b>Keep the app going</b></li>
                                    <li>Dark Mode</li>
                                    <li>No ads</li>
                                    <li>Faster refresh</li>
                                    <li>Later trains</li>
                                </ul>
                            </TableCell>
                            <TableCell>
                                <img src={FrogFile} alt="Logo" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <h2>Install</h2>
                            </TableCell>
                            <TableCell align={"right"}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <h2>Share</h2>
                            </TableCell>
                            <TableCell align={"right"}>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default SettingsPage